<template>
  <div class="dim-box">
    <div class="date-signature flex-3">
      <div id="signatures-and-logo-wrapper">
        <div v-if="by" class="header flex-4">
          <img
            v-viewer.static="options"
            class="signature slide-in-left"
            :src="by"
            alt="Signature"
          />
        </div>
        <div
          id="logo-and-buttons-wrapper"
          class="flex-1 flex-column"
          :class="{ 'flex-row': isInvalid }"
        >
          <img
            v-viewer.static="options"
            class="app-logo tilt-in-top-1"
            :src="projectLogo"
            :class="{ 'mt-0 mr-20 mb-0': isInvalid }"
          />
          <div
            class="w-100 flex flex-column buttons-wrapper"
            :class="{ 'justify-content-space-between': isInvalid, 'mt-20': !isInvalid }"
          >
            <button
              class="my-button mt-0 pr-0 custom-button"
              @click="openLink('https://ezfire.us/')"
              title="About Us"
            >
              <span class="ml-auto mr-5 flex-1">About Us</span>
              <div class="ml-auto mouse-icon-wrapper">
                <img src="@/assets/mouse.svg" />
              </div>
            </button>
            <button
              class="my-button mt-0 pr-0 custom-button"
              @mousedown="$emit('toggle-show-nation-wide')"
              title="We Are Nationwide"
            >
              <span class="ml-auto mr-5 flex-1">We Are Nationwide</span>
              <div class="ml-auto mouse-icon-wrapper">
                <img src="@/assets/mouse.svg" class="" />
              </div>
            </button>
            <button
              class="my-button mb-0 mt-0 pr-0 custom-button"
              @click="openLink('https://ezfire.us/quote')"
              title="Request a Quote"
            >
              <span class="ml-auto mr-5 flex-1"> Request a Quote</span>
              <div class="ml-auto mouse-icon-wrapper">
                <img src="@/assets/mouse.svg" class="" />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "viewerjs/dist/viewer.css";
import { directive as viewer } from "v-viewer";

export default {
  name: "Signature",

  data() {
    return {
      isOpenNationWide: false,
      markers: [],
      options: {
        title: false,
        toolbar: false,
        navbar: false,
        loop: false,
        movable: false,
      },
    };
  },

  props: {
    projectLogo: {
      type: String,
      required: false,
    },

    by: {
      type: String,
      required: true,
    },

    createDate: {
      type: String,
      required: true,
    },

    isInvalid: {
      type: Boolean,
      default: false,
    },
  },

  directives: {
    viewer: viewer({}),
  },

  methods: {
    openLink(link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin: 0 auto;
}

.signature {
  border-radius: 5px 0 0 5px;
  box-shadow: 5px 6px 6px rgb(0 0 0 / 22%);
  width: 100%;
  height: auto;
}

.date-signature {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#logo-and-buttons-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 0 0 0 1em;
  flex: 1;
  max-height: 100%;
  background: #fff;
  border-radius: 0 5px 5px 0;
  padding: 10px;
}

#signatures-and-logo-wrapper {
  max-height: 100%;
  display: flex;
}

.app-logo {
  max-width: 100%;
  max-height: 300px;
  object-fit: contain;
  border-radius: 5px;
  /* box-shadow: 5px 6px 6px rgb(0 0 0 / 22%); */
}

.mouse-icon-wrapper {
  background-color: #686868;
  height: calc(100% + 2px);
  border-radius: 0 5px 5px 0;
  margin-right: -1px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-button {
  height: 50px;
  min-height: 50px;
}

@media screen and (max-width: 1024px) {
  #signatures-and-logo-wrapper {
    flex-direction: column;
    flex-wrap: wrap;
  }

  #logo-and-buttons-wrapper {
    margin-top: 1rem;
    margin-left: 0;
    flex-direction: column;
    align-items: center;
  }

  .app-logo {
    /* max-width: 40%; */
    margin-bottom: 1em;
    height: fit-content;
  }

  .buttons-wrapper {
    margin-top: 0 !important;
  }
}
</style>
